import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

var menuStatus = false;

const button = document.getElementById("menu-button");

const closeButton = document.getElementById("menu-close-button");

closeButton.addEventListener("click", function() {
    document.getElementById("mobile-menu").classList.remove("menu-open"); // Remove class
    menuStatus = false;
});

button.addEventListener("click", function() {
  var mobileMenu = document.getElementById("mobile-menu");
  
  if (menuStatus) {
    mobileMenu.classList.remove("menu-open");
    menuStatus = false;
  } else {
    mobileMenu.classList.add("menu-open");
    menuStatus = true;
  }
});

document.addEventListener('DOMContentLoaded', () => {

  // Menu page active link
  let page = document.body.getAttribute("data-menu-page");
  if(document.querySelector(`[href="/${page}"]`))
    document.querySelector(`[href="/${page}"]`).classList.add("active");


  // Carousel
  const container = document.querySelector('.carousel');
  const items = document.querySelectorAll('.carousel-item');
  const dots = document.querySelectorAll('.item-dot');
  const activeDot = document.querySelector('.active-dot');

  dots.forEach((dot, index) => {
    dot.addEventListener('click', () => {
      const scrollPosition = index * container.offsetWidth;
      container.scrollTo({
        left: scrollPosition,
        behavior: 'smooth'
      });

      activeDot.style.left = `${dots[0].offsetLeft}px`;
    });
  });

  dots.forEach((dot, index) => {
    dot.addEventListener('click', () => {
      const targetLeft = dot.offsetLeft;
      activeDot.style.left = `${targetLeft}px`;
    });
  });


  // Form
  //create mailgun api send function for form with id contact-form
  const form = document.getElementById('contact-form');

  if(form) {
    form.addEventListener('submit', function (e) {
      e.preventDefault();
  
      const name = document.getElementById('name').value;
      const email = document.getElementById('email').value;
      const number = document.getElementById('number').value;
      const message = document.getElementById('message').value;
  
      let body = `Name: ${name}\nEmail: ${email}\nNumber: ${number}\nMessage: ${message}`;
  
      const data = {
        name,
        email,
        message: body
      };

      const formData = new FormData();
      Object.keys(data).forEach(key => formData.append(key, data[key]));

        var status = document.getElementById("form-status");
        fetch(e.target.action, {
          method: form.method,
          body: formData,
          headers: {
              'Accept': 'application/json'
          }
        }).then(response => {
          if (response.ok) {
            status.innerHTML = "Thanks for your submission!";
            form.reset()
          } else {
            response.json().then(data => {
              if (Object.hasOwn(data, 'errors')) {
                status.innerHTML = data["errors"].map(error => error["message"]).join(", ")
              } else {
                status.innerHTML = "Oops! There was a problem submitting your form"
              }
            })
          }
        }).catch(error => {
          status.innerHTML = "Oops! There was a problem submitting your form"
        });

    });
  }


});

